import React, { useState } from "react";
import {
  Typography,
  Box,
  Container,
  Paper,
  Divider,
  List,
  Stack,
  Chip,
  Card,
  CardMedia,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { articlesData, styles } from "../../constants";
import articleimg from "../../assets/article.jpg";
import articleimg2 from "../../assets/article2.png";
import { ArrowBack } from "@mui/icons-material";

export default function Articles() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleReadMore = (index) => {
    setExpandedIndex(index);
  };

  const handleBack = () => {
    setExpandedIndex(null);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box sx={styles.container}>
      {expandedIndex === null && (
        <Container
          sx={{
            paddingLeft: isMobile ? "10px" : "20px", // Adjust padding for mobile
            display: "flex",
            flexDirection: isMobile ? "column" : "row", // Stack cards vertically on mobile
            gap: isMobile ? "10px" : "20px", // Smaller gap on mobile
          }}
        >
          <Card
            sx={{
              maxWidth: 345,
              borderRadius: "15px",
              height: "100%",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
              width: isMobile ? "100%" : "auto", // Full width on mobile
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={articleimg}
              alt="Image"
            />
            <Box sx={{ p: isMobile ? 1 : 2 }}>
              <Typography
                variant={isMobile ? "h6" : "h5"} // Smaller font size on mobile
                gutterBottom
                sx={{ fontWeight: "bold", color: "#2E2E38" }}
              >
                The Importance of Brain Growth and Stimulation in Infants
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: "10px", flexWrap: "wrap" }} // Allow chips to wrap on mobile
              >
                <Chip
                  label="Infant Brain"
                  sx={{
                    backgroundColor: "#ECECEC",
                    color: "#8a8a8a",
                    borderRadius: "20px",
                  }}
                />
                <Chip
                  label="Types of Stimulation"
                  sx={{
                    backgroundColor: "#ECECEC",
                    color: "#8a8a8a",
                    borderRadius: "20px",
                  }}
                />
              </Stack>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "30px",
                  padding: isMobile ? "6px 16px" : "8px 20px", // Adjust button size on mobile
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: isMobile ? "12px" : "14px", // Smaller font on mobile
                }}
                onClick={() => handleReadMore(0)}
              >
                Read more
              </Button>
            </Box>
          </Card>

          {/* Second Card */}
          <Card
            sx={{
              maxWidth: 345,
              borderRadius: "15px",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
              width: isMobile ? "100%" : "auto", // Full width on mobile
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={articleimg2}
              alt="Image"
            />
            <Box sx={{ p: isMobile ? 1 : 2 }}>
              <Typography
                variant={isMobile ? "h6" : "h5"} // Smaller font size on mobile
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "#2E2E38",
                  marginBottom: "36px",
                }}
              >
                The Scientific Effects of Motivation
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ marginBottom: "10px", flexWrap: "wrap" }} // Allow chips to wrap on mobile
              >
                <Chip
                  label="Motivation"
                  sx={{
                    backgroundColor: "#ECECEC",
                    color: "#8a8a8a",
                    borderRadius: "20px",
                  }}
                />
                <Chip
                  label="Dopamine effect"
                  sx={{
                    backgroundColor: "#ECECEC",
                    color: "#8a8a8a",
                    borderRadius: "20px",
                  }}
                />
              </Stack>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "30px",
                  padding: isMobile ? "6px 16px" : "8px 20px", // Adjust button size on mobile
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: isMobile ? "12px" : "14px", // Smaller font on mobile
                }}
                onClick={() => handleReadMore(1)}
              >
                Read more
              </Button>
            </Box>
          </Card>
        </Container>
      )}

      {expandedIndex !== null && (
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{ ...styles.articleBox, p: isMobile ? 2 : 4 }}
          >
            <IconButton
              sx={{
                paddingBottom: "30px",
                ":hover": { boxShadow: "none", backgroundColor: "transparent" },
              }}
              onClick={handleBack}
            >
              <ArrowBack />
            </IconButton>

            <Typography
              variant={isMobile ? "h4" : "h3"} // Smaller heading size on mobile
              gutterBottom
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              {articlesData[expandedIndex].title}
            </Typography>

            <Typography
              variant={isMobile ? "body2" : "subtitle1"} // Smaller subtitle on mobile
              color="textSecondary"
            >
              By {articlesData[expandedIndex].author} |{" "}
              {articlesData[expandedIndex].date}
            </Typography>
            <Divider sx={{ marginY: "20px" }} />

            {/* Content */}
            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={styles.sectionTitle}
            >
              {articlesData[expandedIndex].subtext1}
            </Typography>
            <Typography variant="body1" sx={styles.sectionText}>
              {articlesData[expandedIndex].content1}
            </Typography>

            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={styles.sectionTitle}
            >
              {articlesData[expandedIndex].subtext2}
            </Typography>
            {articlesData[expandedIndex].content2.map((paragraph, idx) => (
              <Typography key={idx} variant="body1" sx={styles.sectionText}>
                {paragraph}
              </Typography>
            ))}

            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={styles.sectionTitle}
            >
              {articlesData[expandedIndex].subtext3}
            </Typography>
            {articlesData[expandedIndex].content3.map((paragraph, idx) => (
              <Typography key={idx} variant="body1" sx={styles.sectionText}>
                {paragraph}
              </Typography>
            ))}

            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={styles.sectionTitle}
            >
              Conclusion
            </Typography>
            <Typography variant="body1" sx={styles.sectionText}>
              {articlesData[expandedIndex].conclusion}
            </Typography>

            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={styles.sectionTitle}
            >
              Resources
            </Typography>
            <List>
              {articlesData[expandedIndex].resources.map((resource, idx) => (
                <Typography key={idx} variant="body1" sx={styles.sectionText}>
                  {resource}
                </Typography>
              ))}
            </List>
          </Paper>
        </Container>
      )}
    </Box>
  );
}
