import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiBaseUrl, products } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeCart, toggleDrawer } from "../../slice/cartSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Divider, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast, ToastContainer } from "material-react-toastify";
import logo from "../../logo.png";
import axios from "axios";

export default function Courses() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCourses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}courses/${userId}`);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        toast.error(err.response.message);
      }
    }
  };

  const [heart, setHeart] = useState(products.map(() => false));
  const handleHeartClick = (index) => {
    const heartArray = [...heart];
    heartArray[index] = !heartArray[index];
    setHeart(heartArray);
  };
  const handlePayment = async () => {
    const orderUrl = "http://localhost:3001/create-order";
    const { data } = await axios.post(orderUrl, {
      // amount: cartItems.reduce((total, items) => total + items.price * 100, 0),
    });

    const options = {
      key: "rzp_live_c8UOUIrjZUbR6S",
      amount: data.amount,
      currency: data.currency,
      image: logo,
      name: "Brain4Brains",
      description: "Test Transaction",

      order_id: data.id,
      handler: async (response) => {
        const verifyUrl = "http://localhost:3001/verify-signature"; // Replace with your backend URL
        const result = await axios.post(verifyUrl, {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        });
        alert(
          result.data.status === "success"
            ? "Payment Successful!"
            : "Payment Failed!"
        );
      },
      prefill: {
        name: "Shakthi",
        email: "shakthi.sri@example.com",
        contact: "0123456789",
      },
      theme: {
        color: "#4F80A8",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const dispatch = useDispatch();
  // const cartItems = useSelector((state) => state.cart.items);
  const [cartItems, setCartItems] = useState([]);
  const getCartItems = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}cart/${userId}`);
      console.log("check", response.data); // Ensure the data is logged properly
      setCartItems(response.data); // Set only the cart data, not the entire response object
    } catch (error) {
      console.error("Error fetching cart items", error);
      // Handle the error accordingly
    }
  };
  const openCart = useSelector((state) => state.cart.isDrawerOpen);
  const loggedin = useSelector((state) => state.login.isLoggedIn);

  // const TotalPrice = cartItems.reduce((total, item) => {
  //   return typeof item.price === "number" ? total + item.price : total;
  // }, 0);
  const handleCheckout = () => {
    if (loggedin) {
      toast.info("Checkout feature is under development");
    } else {
      toast.error("Log in / Sign up to checkout");
    }
  };

  const handleDrawerOpen = () => {
    dispatch(toggleDrawer(true));
  };
  const handleBackToCourses = () => {
    dispatch(toggleDrawer(false));
  };

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleOpenCopyModal = () => {
    setOpen(true);
    setCopied(false);
  };
  const handleCloseCopyModal = () => {
    setOpen(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
  };
  const userId = parseInt(localStorage.getItem("userid"), 10);

  const handleAddToCart = async (item) => {
    try {
      const data = {
        course_id: item.id,
        user_id: userId,
        title: item.title,
        author: item.author,
        hours: item.hours,
        lectures: item.lectures,
        level: item.level,
        price: item.price,
      };
      console.log(data);
      const response = await axios.post(`${apiBaseUrl}add-to-cart/${userId}`, {
        course_id: item.id,
      });
      if (response.status === 200) {
        toast.success("Added to cart");
        console.log(response.data);
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        console.log(err);
        toast.error(err.response.message) ||
          toast.error("Please try again later");
      }
    }
  };
  useEffect(() => {
    getCourses();
    getCartItems();
  }, []);
  return (
    <div>
      <Box
        sx={{
          color: "white",
          minHeight: "78vh",
          display: "flex",
          position: "relative",
          overflow: "hidden",
          gap: "40px",
          backgroundColor: "#BEE4E0",
          width: "100%", // Ensure the Box takes full width
        }}
      >
        {loading ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              margin: "50px auto 10px",
            }}
          >
            <CircularProgress size={80} />
          </div>
        ) : openCart ? (
          <Box sx={{ p: 4, width: "100%" }}>
            {" "}
            {/* Set the Box to full width */}
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {" "}
              {/* Ensure Grid container is full width */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleBackToCourses}
                  sx={{
                    mb: 2,
                    borderRadius: "50px",
                    backgroundColor: "#4F80A8",
                  }}
                >
                  Back to Courses
                </Button>

                {cartItems.length === 0 ? (
                  <Typography variant="h6" sx={{ color: "#4F80A8", mb: 2 }}>
                    No items in cart
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ color: "#4F80A8", mb: 2 }}>
                    {cartItems.length} Course(s) in Cart
                  </Typography>
                )}

                {cartItems.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ display: "flex", mb: 2, width: "100%" }}>
                      {" "}
                      {/* Card takes full width */}
                      <CardMedia
                        component="img"
                        sx={{ width: 200 }}
                        alt={item.title}
                        image={products[index].image}
                      />
                      <CardContent sx={{ flex: 1 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "#4F80A8" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography sx={{ mb: 2, color: "#4F80A8" }}>
                          {item.author}
                        </Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}
                        >
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ mr: 2 }}
                          >
                            Bestseller
                          </Button>
                        </Box>
                        <Typography sx={{ mb: 1, color: "#4F80A8" }}>
                          {item.hours} total hours • {item.lectures} lectures •{" "}
                          {item.level}
                        </Typography>
                        <Button
                          sx={{ color: "purple", mr: 2 }}
                          // onClick={() => HandleremoveCart(item.id)}
                        >
                          Remove
                        </Button>
                        <Button sx={{ color: "purple", mr: 2 }}>
                          Save for Later
                        </Button>
                      </CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", pr: 4 }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "#4F80A8" }}
                        >
                          {typeof item.price === "number"
                            ? "$" + item.price
                            : item.price}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))}

                {/* Checkout and Total Cost Section */}
                {cartItems.length > 0 && (
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor: "#f4f4f9",
                      borderRadius: "16px",
                      mt: 4,
                      boxShadow: 3,
                      width: "100%", // Make sure the checkout section takes full width
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#4F80A8", mb: 2 }}
                    >
                      Order Summary
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Typography variant="h6" sx={{ color: "#4F80A8" }}>
                          Total ({cartItems.length} Course
                          {cartItems.length > 1 ? "s" : ""})
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={{ textAlign: "right" }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", color: "#4F80A8" }}
                        >
                          {/* {typeof TotalPrice === "number"
                            ? "$" + TotalPrice
                            : TotalPrice} */}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 4, textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "#4F80A8",
                          color: "#fff",
                        }}
                        onClick={handlePayment}
                      >
                        Proceed to Checkout
                      </Button>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          data.map((item, index) => (
            <Box sx={{ display: "flex", padding: "0 16px" }} key={index}>
              <Card sx={{ width: 345, margin: "auto", boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="140"
                  // image={products[index].image}
                  alt={item.title}
                />
                <CardContent sx={{ height: 200 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" color="text.primary">
                      {typeof item.price === "number"
                        ? "$" + item.price
                        : item.price}
                    </Typography>
                    {item.isadded === 1 ? (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "50px",
                          backgroundColor: "#4F80A8",
                        }}
                        onClick={handleDrawerOpen}
                      >
                        Added to Cart
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#4F80A8",
                          color: "#fff",
                          borderRadius: "50px",
                        }}
                        onClick={() => handleAddToCart(item)}
                      >
                        Add to Cart
                      </Button>
                    )}
                  </Box>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={() => handleHeartClick(index)}
                  >
                    <FavoriteIcon color={heart[index] ? "error" : "inherit"} />
                  </IconButton>

                  <IconButton aria-label="share" onClick={handleOpenCopyModal}>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Box>
          ))
        )}

        <Modal open={open} onClose={handleCloseCopyModal}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: 4,
              width: 300,
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Share this course
            </Typography>
            <TextField
              fullWidth
              value={window.location.href}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Tooltip title="Copy link">
                    <IconButton onClick={handleCopyLink}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
            {copied && (
              <Typography sx={{ color: "#08024F", marginTop: 2 }}>
                Link copied to clipboard!
              </Typography>
            )}
            <Button
              onClick={handleCloseCopyModal}
              sx={{ marginTop: 3 }}
              variant="contained"
              fullWidth
            >
              Close
            </Button>
          </Paper>
        </Modal>
      </Box>
    </div>
  );
}
